(function($) {
    "use strict";
     
    $(document).ready(function () {
    	$('select.styled').customSelect();
    });
     
    $(document).on('click', '.yamm .dropdown-menu', function(e) {
        e.stopPropagation()
    });

    $('.fashion-v1-position').prev('header').addClass('behind-slider-h');
    $('.fashion-v1-position').next('footer').addClass('behind-slider-f');

})(jQuery);

